import css from './Header.module.css'
import {FiHeart} from 'react-icons/fi'
import {AiOutlineShoppingCart, AiOutlineUserAdd} from 'react-icons/ai'

const Nav = ({handelInputChange}) => {
    return ( 

        <nav>

            {/* {Right SIde of Header} */}


            <div className={css.rightSide}>
                <h1>Monsieur Reza</h1>
            </div>

            {/* {End of Right SIde of Header} */}



            {/* {Left SIde of Header} */}

            <div className={css.leftSide}>

            <div className={css.Search}>
                <input onChange={handelInputChange} type="text" placeholder='Enter Your Search' />
            </div>

            <div className={css.navIcons}>
                <a href="#">
                    <FiHeart className={css.navIcon}></FiHeart>
                </a>
                <a href="#">
                    <AiOutlineShoppingCart className={css.navIcon}></AiOutlineShoppingCart>
                </a>
                <a href="#">
                    <AiOutlineUserAdd className={css.navIcon}></AiOutlineUserAdd>
                </a>
            </div>
            </div>

        {/* {End of Left SIde of Header} */}



        </nav>

     );
}
 
export default Nav;