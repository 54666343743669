import Products from '../Products/Products';
import Sidebar from '../Sidebar/Sidebar';
import './Body.css'

const Body = ({handelChange,hanelClick,result,handelTouch,Filter,setFilter,FilterRef}) => {
    return ( 

        <div className='body'>


             {/* {Filters Side} */}

             
             <div className={`leftSide ${Filter? 'active' : 'inactive'}`}>
            <Sidebar handelChange={handelChange} Filter={Filter}/>
            </div>
             



            {/* {Products Side} */}

            <div className='rightSide'>
            <Products result={result} hanelClick={hanelClick} handelTouch={handelTouch} Filter={Filter} setFilter={setFilter} FilterRef={FilterRef}/>
            </div>
        
        </div>

     );
};
 
export default Body;