import css from './footer.module.css'
import {BiSolidMap} from 'react-icons/bi'
import {BsTelephone,BsPeopleFill} from 'react-icons/bs'
import {AiOutlineMail,AiOutlineLink} from 'react-icons/ai'
import {GoSignIn} from 'react-icons/go'


const Footer = () => {
    return ( 

        <div className={css.cFooterWrapper}>

            <div className={css.Footer}>

                <div className={css.Logo}>
                    <h1>Monsieur Reza</h1>
                </div>

                   {/* {first Block} */}
                <div className={css.block}>
                  <div className={css.detail}>
                      <span>Contact US</span>

                     <span className={css.pngLine}>
                          <BiSolidMap className={css.icon}/>
                          <span>111 north avenue Orlando, FL 32801</span>
                     </span>

                     <span className={css.pngLine}>
                          <BsTelephone className={css.icon}/>
                          <span>+989127062493</span>
                     </span>

                     <span className={css.pngLine}>
                          <AiOutlineMail className={css.icon}/>
                          <span>me.nezhad@gmail.com</span>
                     </span>

                  </div>
                </div>
                {/* {end of first block} */}


                {/* {second block} */}

                <div className={css.block}>
                  <div className={css.detail}>
                      <span>Account</span>

                       <span className={css.pngLine}>
                          <GoSignIn className={css.icon}/>
                          <span>Sign In</span>
                     </span>
                  </div>
                </div>

                {/* {end of second block} */}



                {/* {third block} */}

                <div className={css.block}>
                  <div className={css.detail}>
                      <span>Company</span>

                       <span className={css.pngLine}>
                          <BsPeopleFill className={css.icon}/>
                          <span>About US</span>
                     </span>
                  </div>
                </div>

                {/* {end of third block} */}


                {/* {last block} */}

                <div className={css.block}>
                  <div className={css.detail}>
                      <span>Resources</span>

                       <span className={css.pngLine}>
                          <AiOutlineLink className={css.icon}/>
                          <span>Safety Privacy & Terms</span>
                     </span>
                  </div>
                </div>

                {/* {end of last block} */}

            </div>


            <div className={css.Footer2}>
                <div>Copyright ©2022 by Amazon, Inc.</div>
                <div>All rights reserved.</div>
            </div>

        </div>


        );
}
 
export default Footer;