import {BsFillBagHeartFill} from 'react-icons/bs'
import '../Products/Products.css'


const Card = ({img,title, star, reviews, prevPrice, newPrice}) => {
    return ( 

            <section className="card">
                
                <img src={img} alt={title} />
                <h3 className="card-title">{title}</h3>

                <div className="card-details">

                    <section className="card-reviews">
                        <div>{star}{star}{star}{star}</div>
                        <div className='total-reviews'>{reviews}</div>
                    </section>

                    <section className='card-price'>
                        <div className="price">
                            <span>{prevPrice}</span>
                            <span>{newPrice}</span> 
                        </div>
                        <div className="bag">
                            <BsFillBagHeartFill className='bag-icon'></BsFillBagHeartFill>
                        </div>
                    </section> 

                </div>

            </section>
        
     );
}
 
export default Card;