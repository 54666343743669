import "./Sidebar.css"
// import '../global.css'
import Input from "../components/Input"



const Sidebar = ({handelChange,Filter}) => {
    
    return ( 

        <>

        <section className={`sidebar ${Filter? 'active' : 'inactive'}`}>


            {/* {category section} */}
            <div className="sidebarSections">

              <h2 className="categoryTitle">Category</h2>

              
             <label htmlFor="" className="sidebarLabelContainer">
               <input onChange={handelChange} type="radio" value="" name='test' />
               <span className="Checkmark"></span> All
            </label>

            <Input
            handelChange={handelChange}
            value='sneakers'
            title='Sneakers'
            name='test' 
            />

           <Input
           handelChange={handelChange}
           value='flats'
           title='Flats'
           name='test' 
           />

           <Input
           handelChange={handelChange}
           value='sandals'
           title='Sandals'
           name='test' 
           />

         <Input
         handelChange={handelChange}
         value='heels'
         title='Heels'
         name='test' 
         />

        

            </div>


            {/* {Price section} */}
            <div className="sidebarSections">
            
            <h2 className="categoryTitle">Price</h2>

            <label htmlFor="" className="sidebarLabelContainer">
                <input onChange={handelChange} type="radio" value="" name='test2' />
                <span className="Checkmark"></span> All
            </label>

            <Input
            handelChange={handelChange}
            value={50}
            title='$0-50'
            name='test2'
            />

            <Input
            handelChange={handelChange}
            value={100}
            title='$50-100'
            name='test2'
            />

            <Input
            handelChange={handelChange}
            value={150}
            title='$100-150'
            name='test2'
            />

            <Input
            handelChange={handelChange}
            value={200}
            title='Over $200'
            name='test2'
            />

            </div>


            {/* {Color section} */}

            <div className="sidebarSections">
            
            <h2 className="categoryTitle">Colors</h2>
    
            <label className="sidebarLabelContainer">
                    <input onChange={handelChange} type="radio" value="" name='test3' />
                    <span className="Checkmark"></span> All
            </label>
    
            <Input
            handelChange={handelChange}
            value='black'
            title='Black'
            name='test3'
            color='black'
            />
    
            <Input
            handelChange={handelChange}
            value='blue'
            title='Blue'
            name='test3'
            color='blue'
            />
    
    
            <Input
            handelChange={handelChange}
            value='red'
            title='Red'
            name='test3'
            color='red'
            />
    
            <Input
            handelChange={handelChange}
            value='green'
            title='Green'
            name='test3'
            color='green'
            />
    
            <Input
            handelChange={handelChange}
            value='white'
            title='White'
            name='test3'
            color='white'
            />
    
            </div>


        </section>
        
        </>
     );
}
 
export default Sidebar;