import css from '../Sidebar/Sidebar.css'

const Input = ({handelChange, value, title, name, color}) => {
    return ( 
    <>
        <label className="sidebarLabelContainer">
            <input onChange={handelChange} type="radio" value={value} name={name} />
            <span className='Checkmark'></span>{title}
        </label>
    </>
     );
}
 
export default Input;