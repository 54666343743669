import './Products.css'
import Buttons from '../components/Buttons';
import {AiOutlineMenu} from 'react-icons/ai'
import { useState } from 'react';

const Products = ({result, hanelClick,handelTouch,FilterRef}) => {


    return ( 
        <>
           {/* {Recomended Section} */}

            <div className='recomended-section'>

             <div>
             <h2 className='recommended-title'>RECOMMENDED</h2>
             <div onClick={handelTouch}  ref={FilterRef}>
               <span>Filter</span>
             <AiOutlineMenu/>
             </div>
             </div>

             <div className='btns'>
                <Buttons onClickHandeler={hanelClick} value='' title='All Products'/>
                <Buttons onClickHandeler={hanelClick} value='Nike' title='Nike'/>
                <Buttons onClickHandeler={hanelClick} value='Adidas' title='Adidas'/>
                <Buttons onClickHandeler={hanelClick} value='Puma' title='Puma'/>
                <Buttons onClickHandeler={hanelClick} value='Vans' title='Vans'/>
             </div>
            </div>

            {/* {End of Recomended Section} */}



            {/* {Start Products Section} */}

             <section className='card-container'>

               {result}

             </section>

            {/* {End of Products Section} */}

        
        </>

     );
}
 
export default Products;