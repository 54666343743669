import { useEffect, useRef, useState } from "react";
import './index.css'
import Header from "./Header/Header";
import Card from "./components/Card";
import Body from "./Body/Body";
import Footer from "./Footer/footer";

//Database
import products from './db/data'

function App() {  


  // ...................... Filter Button in Mobile .......................

  const [Filter , setFilter] = useState(false) 

  const handelTouch = ()=>{
     setFilter(Filter=>!Filter)
     console.log(Filter)
  }

  let FilterRef = useRef();

  useEffect (()=>{

    const handeler = (e)=>{

      if(!FilterRef.current.contains(e.target)){
        setFilter(false);
      }
      console.log(e.target)
    }
    
    document.addEventListener('mousedown', handeler)
  })


  const[selectedCategory, setSelectedCategory] = useState(null);

  //...............Input Filter.........................

  const [query, setQuery] = useState('');

  const handelInputChange = event =>{
    setQuery(event.target.value)
  }

  const filteredItems = products.filter((product)=> product.title.toLocaleLowerCase().indexOf(query.toLocaleLowerCase())!== -1)

  //...............Radio Filter.........................

  const handelChange = event =>{
    setSelectedCategory(event.target.value)
  }

    //...............Buttons Filter.........................

    const hanelClick = event =>{
      setSelectedCategory(event.target.value)
    }


    function filterdData(products, selected, query){
      let fillteredProducts = products;

      //Filtering Input Items

      if (query){
        fillteredProducts = filteredItems;
      };

      //Selected Filter

      if(selected){
        fillteredProducts = fillteredProducts.filter(({category,color, company,
           newPrice, title})=> category === selected || color === selected ||
           company === selected || newPrice ===selected || title===selected )
      };


      return fillteredProducts.map(({img,title, star, reviews, prevPrice, newPrice})=>
      <Card key={Math.random()}
      img={img}
      title={title}
      star={star}
      reviews={reviews}
      prevPrice={prevPrice}
      newPrice={newPrice}
      
      />
      )
    };

    const result = filterdData(products,selectedCategory,query);



  return (

    <>
    <Header query={query} handelInputChange={handelInputChange}/>
    <Body handelTouch={handelTouch} Filter={Filter} setFilter={setFilter} handelChange={handelChange} hanelClick={hanelClick} result={result} FilterRef={FilterRef} />
    <Footer/>

    </>

  );
}

export default App;
